<template>
    <div id="home">
        <!-- Header -->
        <header>
            <div class="section-content">
                <div class="container">
                    <div class="main-panel">
                        <h1 class="main-title mb-3">
                            Декоративная штукатурка в Самаре от производителя
                        </h1>
                        <p class="main-text lg-linesize mb-4">
                            От 450 руб. за квадратный метр.
                        </p>
                        <div class="d-flex">
                            <router-link to="/catalog?a=sell" class="btn btn-md style-secondary-active default$ border-lg-rounded">
                                Каталог
                            </router-link>
                            <router-link to="/contacts" class="btn btn-md style-active default$ ml-2 border-lg-rounded">
                                Контакты
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <!-- Advantages -->
        <section id="advantages">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 text-center text-md-left">
                            <p class="h1 styled-header mb-4">Преимущества</p>
                        </div>
                    </div>
                    <!-- Cards -->
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card card-advantage">
                                <div class="card-body border-rect p-0">
                                    <i class="card-icon card-icon-lg style-semi-secondary-active p-3 mb-3 fa fa-coins"></i>
                                    <p class="card-title">
                                        Выгодная цена
                                    </p>
                                    <div class="card-text">
                                        Экономия до 50%
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card card-advantage">
                                <div class="card-body border-rect p-0">
                                    <i class="card-icon card-icon-lg style-semi-secondary-active p-3 mb-3 fa fa-award"></i>
                                    <p class="card-title">
                                        Долговечность
                                    </p>
                                    <div class="card-text">
                                        Гарантия 10 лет на все материалы
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <div class="card card-advantage">
                                <div class="card-body border-rect p-0">
                                    <i class="card-icon card-icon-lg style-semi-secondary-active p-3 mb-3 fa fa-leaf"></i>
                                    <p class="card-title">
                                        Экологичность
                                    </p>
                                    <div class="card-text">
                                        Все наши материалы на водной основе
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Catalog (Products) -->
        <CatalogSection sectionStyle="basic"/>

        <!-- Reviews -->
        <section id="reviews">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center text-md-left">
                            <p class="h1 styled-header mb-4">Отзывы</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-5">
                            <div class="card card-horizontal card-max card-review">
                                <div class="card-body d-flex flex-column p-4">
                                    <div class="row d-flex">
                                        <img v-for="imgUrl in images" :key="imgUrl" class="openable" v-on:click="show" v-lazy="getImageSrc(imgUrl)" alt="Фото отзыва">
                                    </div>

                                    <div class="card-text mb-3">
                                        Осенью 2020 года обратился к ребятам из компании домус декор, для отделки стен декоративной штукатуркой. Их мне посоветовал товарищ которому на тот момент уже нанесли покрытие на стены, оперативно и качественно. Он был в восторге 👍 Но для меня ещё была важна цена, так как бюджет был ограничен. Выбрали с женой материал по цвету и фактуре относящиеся к виду шелковых штукатурок, подобных материалов достаточно много. В итоге остановились на штукатурке Арганза торговой марки домус декор и технике нанесения драпе (разно-направленными мазками).
                                        <br>
                                        В сравнении с другими брендами получалось экономия на 20-30%
                                        <br>
                                        Сделали предоплату, через три дня ребята уже приступили к работе. Объем 96м.кв выполняли за 3 дня. Смотрится все отлично 👌 Самое главное что эта штукатурка хорошо моется, у нас двое маленьких детей, отмывали и варенье и печенье ✨. Я даже в одном месте сам отреставрировал поврежденный участок стены. Спасибо ребятам за работу Олегу и Сергею!
                                    </div>

                                    <p class="card-title ml-auto mt-auto mb-0">
                                        Алексей
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex">
                            <a href="/reviews" class="btn btn-md style-active default$ font-title mx-auto">Посмотреть все</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="image-viewer" ref="zoomedImageViewer" @click.self="hide" style="display: none;">
            <button class="close" @click.prevent="hide"></button>
            <img ref="zoomedImage" src="" alt="Увеличенная фотография отзыва">
        </div>

        <LocationSection />
    </div>
</template>

<script>
import CatalogSection from '../components/blocks/CatalogSection.vue'
import LocationSection from '../components/blocks/LocationSection.vue'

export default {
    name: 'Home',
    methods: {
        getImageSrc: function(name) {
            return require(`../assets/img/reviews/${name}`)
        },
        show: function(event) {
            this.$refs.zoomedImageViewer.style.display = 'block'
            this.$refs.zoomedImage.setAttribute('src', event.target.getAttribute('src'))
        },
        hide: function() {
            this.$refs.zoomedImageViewer.style.display = 'none'
        }
    },
    data: function() {
        return {
            images: [
                'alexey1.jpeg',
                'alexey2.jpeg',
                'alexey3.jpeg',
            ]
        }
    },
    components: {
        CatalogSection,
        LocationSection
    }
}
</script>